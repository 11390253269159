<template>
  <div>
    <b-card title="">
      <operativesFilterBar :limit-to-applicants="true" />
    </b-card>
    <b-card>
      <listOperativesTable :limit-to-applicants="true" />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import listOperativesTable from '@/components/operatives/ListOperativesTable.vue'
import operativesFilterBar from '@/components/operatives/OperativesFilterBar.vue'

export default {
  middleware: ['check-auth', 'auth'],
  components: {
    BCard,
    listOperativesTable,
    operativesFilterBar,
  },
}
</script>

<style>

</style>
